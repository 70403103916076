<template>
  <div>
    <div class="main">
      <div ref="topEl" class="top" :class="{ active: isOpen }">
        <div>
          <div class="container">
            <div class="content">
              <h4 class="title-content">{{ $t("contact.txtContact") }}</h4>
              <div style="font-size: 14px" v-html="appConfig.contacts"></div>
            </div>
          </div>
          <form class="formulario container" @submit.prevent="sendMessage">
            <div class="content">
              <h1 class="title-form hidden-desktop">
                {{ $t("contact.txtContact") }}
              </h1>
              <input
                class="form-input"
                type="text"
                :placeholder="$t('contact.inputs.placeholderName')"
                v-model="$v.formData.name.$model"
                required
              />
              <!-- <div class="error" v-if="!$v.formData.name.required">Preencha os campos</div> -->
              <input
                class="form-input"
                type="email"
                placeholder="E-mail"
                v-model="$v.formData.email.$model"
                required
              />
              <textarea
                class="form-input"
                :placeholder="$t('contact.inputs.placeholderMsg')"
                required
                cols="25"
                rows="5"
                v-model="$v.formData.description.$model"
              ></textarea>
              <div class="button--submit">
                <button class="send" :disabled="responseAlert">
                  <template v-if="!responseAlert">
                    {{ $t("contact.inputs.buttonSend") }}
                  </template>
                  <div class="spring-spinner" v-else>
                    <div class="spring-spinner-part">
                      <div class="spring-spinner-rotator"></div>
                    </div>
                    <div class="spring-spinner-part bottom">
                      <div class="spring-spinner-rotator"></div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </form>
          <button
            class="more hidden-mobile"
            :class="{ active: isOpen }"
            @click="toogleContact()"
          > +</button>
          <footer class="footer">
            <div class="content">
              <span>
                <p>Powered by</p>
                <a href="https://www.digitalfactory.co.ao" alt="https://digitalfactory.co.ao" target="_blank">
                <img src="@/assets/DIGITAL_FACTORY_WHITE.png" width="80" alt="logotipo digital factory" >
                </a>
              </span>
            </div>
          </footer>

        </div>
      </div>
      <div ref="bottomEl" class="down" :class="{ active: isOpen }"></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: 'Contact',
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      email: { required, email },
      description: { required }
    }
  },
  metaInfo: {
    title: 'Contacts - Ucall'
    /* meta: [
      { name: "description", content: $t('contact.description') },
      { name: "keywords", content: $t('contact.keywords') },
      { property: 'og:title', content: 'title' },
      { property: 'og:url', content: 'http://www.ucall.co.ao' },// here it is just ngrok for my test
      { property: 'og:image', content: require('@/assets/UDigital.jpg') },
      { property: 'twitter:image:src', content: require('@/assets/UDigital.jpg') }
    ] */
  },
  data () {
    return {
      isOpen: false,
      formData: {
        name: '',
        email: '',
        description: ''
      },
      responseAlert: false
    }
  },
  computed: {
    appConfig () {
      return this.$store.getters.appConfig
    }
  },
  methods: {
    toogleContact () {
      this.isOpen = !this.isOpen
    },
    async sendMessage () {
      this.responseAlert = true
      // form/send
      let obj = {}
      const formData = {
        Nome: this.formData.name,
        Email: this.formData.email,
        EmailTo: 'info@ucall.co.ao',
        Texto: this.formData.description
      }

      await axios
        .post(process.env.VUE_APP_URL_API + '/form/send?', formData, {
          headers: {
            ContentType: 'Application/json'
          }
        })
        .then(data => {
          if (data.data === 'Exito') {
            obj = {
              message: this.$t('contact.contactSucceeded'),
              type: 'success'
            }
          }
        })
        .catch(_ => {
          obj = {
            message: this.$t('contact.contactNotSucceeded'),
            type: 'error'
          }
        })

      this.responseAlert = false
      Object.keys(this.formData).forEach(prop => {
        this.formData[prop] = ''
      })
      this.$notification({
        title: 'Resultado',
        ...obj
      })
    }
  }
}
</script>
<style scoped>
.main, .top, h1, .content, .send, .form {
  box-sizing: border-box;
}
.main {
  color: #fff;
  height: 100vh;
  overflow: hidden;
}
.main .top {
  height: 50vh;
  display: flex;
  flex-direction: column;
  background-color: #C42663;
  overflow: hidden;
  z-index: 3;
  transition: height 200ms linear 0s;
  position: relative;
}
.main .top.active {
  overflow: auto;
  height: 100vh;
  transition: height 200ms linear 0s;
}
.main .top .container, .top footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main .top .container:nth-child(1) {
  height: 50vh;
}
.main .top .container:nth-child(2) {
  max-height: 50vh;
  margin: .5em 0 1em 0;
}
.top .container:nth-child(1) .content{
  margin-top: 3.3em;
}
.top .container .content, .top footer .content {
  width: 82%;
}
.top .container .content div p {
  font-family: "gilroy", sans-serif !important;
}

.container .content .form-input {
  width: 60%;
  background-color: transparent;
  border: 2px solid rgba(250, 250, 250, 0.7);
  padding: 8px 20px;
  margin: 5px;
  transition: border 0.26s ease-in;
  box-sizing: border-box;
  color: #fff;
  outline: none;
  border-radius: 8px;
  font-family: "gilroy", sans-serif;
}
@media only screen and (max-width: 768px) {
  .container .content .form-input {
    width: 100%;
  }
}
.container .content .form-input:focus {
  border-color: #fff;
}
.container .content .form-input::placeholder {
  color: rgba(250, 250, 250, 0.4);
}
.container .content h1.title-form {
  font-size: 1.4em;
}
/** footer */
footer .content {
  text-align: center;
}

footer .content span p {
  color: rgba(255,255,255,0.6) !important;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: .3em;
  letter-spacing: 1px;
}
/** component down */
.down {
  height: 50vh;
  background-image: url(../assets/shutterstock_1177902208.png);
  background-attachment: local;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  transform: translateY(0);
  transition: transform 200ms linear -200ms;
}
.down.active {
  transform: translateY(50vh);
  transition: transform 200ms linear -200ms;
}
.pre {
  white-space: pre-line;
  text-transform: uppercase;
}
/* breakpoint tablet */
@media only screen and (max-width: 768px) {
  .top .container:nth-child(1) .content {
    font-size: .9em;
  }
  .send {
    width: 100%;
  }
}
.title-content {
  margin-bottom: 20px;
  display: none;
}
@media only screen and (max-width: 768px) {
  .title-content {
    display: block;
  }
}
.more {
  border-style: none;
  outline-style: none;
  padding: 0px 8px;
  border-radius: 50px;
  font-size: 1.5em;
  color: #d13d72;
  font-weight: 900;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 8px rgba(100, 100, 100, 0.4);
  position: absolute;
  right: 30px;
  bottom: 15px;
  transform: rotate(0deg);
  transition: transform 300ms linear 250ms;
  z-index: 3;
}
.more.active {
  transform: rotate(45deg);
  transition: transform 300ms linear 250ms;
}
.send {
  padding: 8px 40px;
  color: #fff;
  border: 2px solid rgba(250, 250, 250, 0.7);
  outline: none;
  position: relative;
  cursor: pointer;
  text-align: center;
  margin: 3px 0 0 3px;
  border-radius: 8px;
}

.send:disabled {
  cursor: not-allowed;
}

/* D E S K T O P */

@media only screen and (min-width: 768px) {
  [class*="hidden-desktop"] {
    display: initial !important;
  }
  .button--submit {
    display: flex;
    justify-content: flex-start;
  }
  .button--submit button {
    align-self: flex-start;
  }
  .top footer {
    opacity: 1;
    visibility: visible;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 50%;
  }
  .top footer .content {
    text-align: left;
  }
  .hidden-desktop.logo-ucall {
    display: block;
  }
  .hidden-mobile {
    display: none;
  }
  .logo-ucall {
    position: absolute;
    top: 20px;
    left: 50px;
    z-index: 2;
  }
  .main {
    display: flex;
    justify-content: space-between;
  }
  .header-main {
    display: none;
  }
  .down {
    background-image: url(../assets/shutterstock_1177902208.png);
  }
  .main .down,
  .main .top {
    height: 100vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .top {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .top div {
    display: flex;
    flex-direction: column;
  }
  .top .container {
    height: auto !important;
    margin-bottom: 2em;
  }
  .top  .container:nth-child(1) {
    order: 2;
  }
  .top .container:nth-child(2) {
    margin-top: 3em;
  }
  .top .container:nth-child(1) .content{
  margin-top: 0;
  align-self: center;
}

  .title-form {
    align-self: flex-start;
    /* margin: 0 auto; */
    margin-bottom: 12px;
    color: #fff;
    width: 75%;
  }
  .conteudo {
    align-self: flex-start;
    width: 75%;
    margin: 0 auto;
  }
}

/* epic spinner */
.spring-spinner,
.spring-spinner * {
  box-sizing: border-box;
}

.spring-spinner {
  height: 30px;
  width: 30px;
}

.spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(30px / 2);
  width: 30px;
}

.spring-spinner .spring-spinner-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
  width: 30px;
  height: 30px;
  border: calc(30px / 7) solid transparent;
  border-right-color: #fff;
  border-top-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7);
  }
  25% {
    border-width: calc(60px / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(60px / 7);
  }
  75% {
    border-width: calc(60px / 23.33);
  }
  100% {
    border-width: calc(60px / 7);
  }
}
</style>
